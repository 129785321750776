import logo from './LogoPP.png';
import './App.css';

function App() {
  return (
    <div className="App">
{/*       <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}
      <body>
      <img src={logo} className="App-logo"/>
      <h3>🎉 ¡SORTEO! 🎉</h3>

<p>¿Quieres ganar 2 entradas 🎟️ para el espectáculo de George Harris - Hijo Único 🤴🏻 en Madrid el 29 de Octubre? ¡Te lo ponemos fácil! 🤩</p>

<p><b>Para participar:</b></p>

<p>- Asegúrate de SEGUIR a <a href="https://instagram.com/placerproducciones">@placerproducciones</a> para estar a la última sobre los mejores eventos para el público venezolano en Madrid 📸</p>
<p>- COMENTA la publicación del sorteo ETIQUETANDO a 3 amigos para que también puedan participar</p>
<p>- DALE LIKE para que sepamos cuántas ganas tienes de que llegue el día 29 de octubre 🎉 </p>

<p><b>¡Así de sencillo! 🔥</b></p>
<p>La participación se cerrará el 29 de septiembre a las 20:00hs y anunciaremos al ganador el mismo día a las 22:00hs en nuestras redes sociales.</p>
<p>¡No te pierdas esta oportunidad única de disfrutar de un espectáculo increíble!</p>

<p>¡Mucha suerte a todos! 🍀</p>

<a href="/bases-legales">Bases legales de la promoción</a>
      </body>
    </div>
  );
}

export default App;
